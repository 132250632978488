import xmlParser from 'fast-xml-parser';
import marked from 'marked';

const ARTICLE_COUNT = 6;

// prevent marked from wrapping lines in `<p>`
marked.use({
  renderer: {
    paragraph(text) {
      return text;
    },
  },
});

const parseArticlePubDate = (pubDate) => {
  const date = new Date(pubDate);
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');

  return {
    iso8601: date.toISOString(),
    pretty: `${year}-${month}-${day}`,
  };
};

export default async () => {
  let rssFeed;

  try {
    rssFeed = await (await fetch('https://viewsource.io/feed.xml')).text();
  } catch (error) {
    console.error(error);
  }

  const {
    rss: {
      channel: { item: articles },
    },
  } = xmlParser.parse(rssFeed);

  return articles
    .map(({ guid, link, pubDate, title }) => ({
      guid,
      link,
      pubDate: parseArticlePubDate(pubDate),
      title: marked(title),
    }))
    .slice(0, ARTICLE_COUNT);
};
