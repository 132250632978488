//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      projects: [
        {
          title: 'Message Box',
          url: 'https://saulhardman.com/projects/message-box/',
          image: {
            url: 'https://res.cloudinary.com/viewsource-io/image/upload/v1656418072/rhqirdnfvemzq1ts7xy8.jpg',
            width: 1440,
            height: 1080,
          },
        },

        {
          title: 'Flask',
          url: 'https://saulhardman.com/projects/flask/',
          image: {
            url: 'https://res.cloudinary.com/viewsource-io/image/upload/v1656418071/td1znfieyvzw7txxnct8.jpg',
            width: 1440,
            height: 1080,
          },
        },
      ],

      imageWidths: [300, 600, 1200],
    };
  },
};
