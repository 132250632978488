import axios from 'axios';

const GET_PINNED_ITEMS = `
  query {
    user(login: "saulhardman") {
      pinnedItems(first: 6) {
        edges {
          node {
            ... on Repository {
              id
              name
              description
              createdAt
              forks {
                totalCount
              }
              languages(first: 10) {
                edges {
                  node {
                    name
                  }
                }
              }
              url
              issues {
                totalCount
              }
              stargazers {
                totalCount
              }
              updatedAt
              watchers {
                totalCount
              }
              owner {
                login
              }
            }
          }
        }
      }
    }
  }
`;

export default async (personalAccessToken) => {
  let repositories;

  try {
    const {
      data: { data },
    } = await axios.post(
      'https://api.github.com/graphql',
      {
        query: GET_PINNED_ITEMS,
      },
      {
        headers: {
          Authorization: `Bearer ${personalAccessToken}`,
        },
      },
    );

    repositories = data.user.pinnedItems;
  } catch (error) {
    console.error(error);

    return [];
  }

  return repositories.edges.map(
    ({
      node: {
        createdAt,
        description,
        forks,
        id,
        issues,
        languages,
        name,
        owner: { login: ownerLogin },
        stargazers,
        updatedAt,
        url,
        watchers,
      },
    }) => ({
      createdAt: new Date(createdAt).toDateString(),
      description,
      forksCount: forks.totalCount,
      id,
      languages: languages.edges.map(({ node: { name } }) => name),
      name,
      openIssuesCount: issues.totalCount,
      ownerLogin,
      stargazersCount: stargazers.totalCount,
      updatedAt: new Date(updatedAt).toDateString(),
      url,
      watchersCount: watchers.totalCount,
    }),
  );
};
