//
//
//
//
//
//
//
//

import ResponsiveImage from '~/components/ResponsiveImage.vue';

const FETCH = 'fetch';
const UPLOAD = 'upload';
const URL_TYPES = [FETCH, UPLOAD];

export default {
  name: 'VueCloudinaryImage',

  components: {
    ResponsiveImage,
  },

  inheritAttrs: false,

  props: {
    sourceUrl: {
      type: String,

      required: true,
    },

    sourceWidth: {
      type: Number,

      default: null,
    },

    sourceHeight: {
      type: Number,

      default: null,
    },

    aspectRatio: {
      type: String,

      default() {
        if (this.sourceWidth && this.sourceHeight) {
          return `${this.sourceWidth} / ${this.sourceHeight}`;
        }

        throw new Error(
          'Either `aspectRatio` or `sourceWidth` and `sourceHeight` props must be set.',
        );
      },
    },

    widths: {
      type: Array,

      required: true,
    },

    cloudinaryAccount: {
      type: String,

      default() {
        if (process.env.CLOUDINARY_ACCOUNT) {
          return process.env.CLOUDINARY_ACCOUNT;
        }

        throw new Error(
          'The `cloudinaryAccount` prop or environment variable `process.env.CLOUDINARY_ACCOUNT` must be set.',
        );
      },
    },

    urlType: {
      type: String,

      validator: (value) => URL_TYPES.includes(value),

      default: FETCH,
    },

    srcWidth: {
      type: Number,

      default() {
        return this.widths[0];
      },
    },

    transformations: {
      type: Array,

      default() {
        return ['f_auto', 'q_80', 'c_fill'];
      },
    },

    includeHeightDescriptor: {
      type: Boolean,

      default: false,
    },
  },

  computed: {
    baseUrl() {
      return `https://res.cloudinary.com/${this.cloudinaryAccount}/image/${this.urlType}`;
    },

    src() {
      const width = this.srcWidth;
      const height = Math.round(width / this.numericAspectRatio);

      return this.cloudinaryUrl(width, height);
    },

    srcset() {
      let sourceWidths = this.widths;

      if (this.sourceWidth) {
        sourceWidths = this.widths.filter((width) => width <= this.sourceWidth);
      }

      return sourceWidths
        .map((width) => {
          const height = Math.round(width / this.numericAspectRatio);
          const source = this.cloudinaryUrl(width, height);

          return [
            source,
            `${width}w`,
            this.includeHeightDescriptor && `${height}h`,
          ]
            .filter(Boolean)
            .join(' ');
        })
        .join(', ');
    },

    numericAspectRatio() {
      const [width, height] = this.aspectRatio
        .split('/')
        .reduce((acc, value) => [...acc, parseInt(value)], []);

      return width / height;
    },
  },

  methods: {
    cloudinaryUrl(width, height) {
      const transformations = [
        `w_${width}`,
        `h_${height}`,
        ...this.transformations,
      ]
        .filter(Boolean)
        .join(',');

      // NOTE: dealing with a Cloudinary media library URL
      if (this.sourceUrl.startsWith(this.baseUrl)) {
        const imagePath = this.sourceUrl.replace(`${this.baseUrl}/`, '');

        return `${this.baseUrl}/${transformations}/${imagePath}`;
      }

      const sourceUrl =
        this.urlType === FETCH
          ? encodeURIComponent(this.sourceUrl)
          : this.sourceUrl;

      return `${this.baseUrl}/${transformations}/${sourceUrl}`;
    },
  },
};
