var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"c-repository-list m-0 p-0 list-none"},_vm._l((_vm.repositories),function(ref){
    var createdAt = ref.createdAt;
    var description = ref.description;
    var forksCount = ref.forksCount;
    var id = ref.id;
    var languages = ref.languages;
    var name = ref.name;
    var openIssuesCount = ref.openIssuesCount;
    var ownerLogin = ref.ownerLogin;
    var stargazersCount = ref.stargazersCount;
    var updatedAt = ref.updatedAt;
    var url = ref.url;
    var watchersCount = ref.watchersCount;
return _c('li',{key:id,staticClass:"c-repository-list__list-item"},[_c('code-terminal',_vm._b({},'code-terminal',{
        createdAt: createdAt,
        description: description,
        forksCount: forksCount,
        languages: languages,
        name: name,
        openIssuesCount: openIssuesCount,
        ownerLogin: ownerLogin,
        stargazersCount: stargazersCount,
        updatedAt: updatedAt,
        url: url,
        watchersCount: watchersCount,
      },false))],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }