import { render, staticRenderFns } from "./ProjectList.vue?vue&type=template&id=ac096180&"
import script from "./ProjectList.vue?vue&type=script&lang=js&"
export * from "./ProjectList.vue?vue&type=script&lang=js&"
import style0 from "./ProjectList.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/Users/saulhardman/code/saulhardman.com/site/components/BaseImage.vue').default,ActionLink: require('/Users/saulhardman/code/saulhardman.com/site/components/ActionLink.vue').default})
