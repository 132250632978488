//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import autotyper, { EVENTS } from 'autotyper';

export default {
  props: [
    'createdAt',
    'description',
    'forksCount',
    'id',
    'languages',
    'name',
    'openIssuesCount',
    'ownerLogin',
    'stargazersCount',
    'updatedAt',
    'url',
    'watchersCount',
  ],

  data() {
    return {
      command: `cd /${this.name}/`,
    };
  },

  computed: {
    formattedLanguages() {
      return this.languages.join(', ');
    },
  },

  mounted() {
    this.autotyper = Object.create(autotyper);

    this.autotyper.init({
      autoStart: false,
      text: this.command.replace(/\s/g, '\xA0'),
      empty: '',
      interval: [100, 200],
    });

    this.autotyper.on(EVENTS.TYPE, (text) => {
      this.command = text;
    });
  },

  beforeDestroy() {
    this.autotyper.destroy();
  },

  methods: {
    handleMouseEnter() {
      this.autotyper.empty().start();
    },

    handleMouseLeave() {
      this.autotyper.stop().fill().emit(EVENTS.TYPE, this.autotyper.text);
    },
  },
};
