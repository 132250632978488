import { render, staticRenderFns } from "./CloudinaryImage.vue?vue&type=template&id=1f51cf52&"
import script from "./CloudinaryImage.vue?vue&type=script&lang=js&"
export * from "./CloudinaryImage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResponsiveImage: require('/Users/saulhardman/code/saulhardman.com/site/components/ResponsiveImage.vue').default})
