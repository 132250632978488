import { render, staticRenderFns } from "./index.vue?vue&type=template&id=293b6b7e&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WorkList: require('/Users/saulhardman/code/saulhardman.com/site/components/WorkList.vue').default,ProjectList: require('/Users/saulhardman/code/saulhardman.com/site/components/ProjectList.vue').default,ArticleList: require('/Users/saulhardman/code/saulhardman.com/site/components/ArticleList.vue').default,RepositoryList: require('/Users/saulhardman/code/saulhardman.com/site/components/RepositoryList.vue').default})
