import sanity from '~/assets/js/sanity-client.js';
import { parseDate } from '~/assets/js/utilities.js';

export const fetchEducation = async () =>
  (
    await sanity.fetch(
      /* groq */
      `*[_type == "education"]{
            _id,
            endDate,
            institution,
            startDate,
            qualifications,
          } | order(endDate desc)`,
    )
  ).map((position) => {
    const { startDate, endDate } = position;

    return {
      ...position,

      startDate: parseDate(startDate),

      ...(endDate ? { endDate: parseDate(endDate) } : {}),
    };
  });

export const fetchPositions = async () =>
  (
    await sanity.fetch(
      /* groq */
      `*[_type == "position"]{
            _id,
            description,
            employmentType,
            endDate,
            links,
            location,
            organisation,
            role,
            startDate,
            technologies,
          } | order(endDate desc)`,
    )
  ).map((position) => {
    const { startDate, endDate } = position;

    return {
      ...position,

      startDate: parseDate(startDate),

      ...(endDate ? { endDate: parseDate(endDate) } : {}),
    };
  });

export const fetchProjects = async () =>
  await sanity.fetch(
    /* groq */
    `*[_type == "project"]{
            _id,
            title,
            description,
            url,

            image {
              url,
              width,
              height
            }
          } | order(publishedAt desc)`,
  );
