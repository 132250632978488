import sanityClient from '@sanity/client';

const projectId = process.env.SANITY_PROJECT_ID;
const dataset = process.env.SANITY_DATASET;

export default sanityClient({
  projectId,
  dataset,
  useCdn: false,
  apiVersion: '2021-08-31',
});
