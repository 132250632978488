//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration';

import fetchRepositories from '~/assets/js/services/github';
import fetchArticles from '~/assets/js/services/rss';
import { fetchProjects as fetchWorks } from '~/assets/js/services/sanity';
import { SITE_TITLE } from '~/assets/js/constants';
import { indexUrl } from '~/assets/js/urls';

let head;

if (process.server) {
  head = require('~/utilities/head').default;
}

export default {
  components: {
    LazyHydrate,
  },

  async asyncData({ $config: { githubPersonalAccessToken } }) {
    const [repositories, articles, works] = await Promise.all([
      fetchRepositories(githubPersonalAccessToken),
      fetchArticles(),
      fetchWorks(),
    ]);

    return {
      repositories,
      articles,
      works,
    };
  },

  head() {
    if (process.server) {
      return head('index');
    }

    return {
      title: SITE_TITLE,

      link: [{ hid: 'canonical', rel: 'canonical', href: indexUrl(true) }],
    };
  },
};
