//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CloudinaryImage from '~/components/CloudinaryImage.vue';

export default {
  components: {
    CloudinaryImage,
  },

  inheritAttrs: false,

  props: {
    sourceUrl: { type: String, required: true },
    sourceWidth: { type: Number, required: true },
    sourceHeight: { type: Number, required: true },
    tagName: { type: String, default: 'figure' },

    widths: {
      type: Array,
      default: () => [375, 480, 750, 960, 1200, 1600, 2400],
    },

    aspectRatio: {
      type: String,

      default() {
        if (this.sourceWidth && this.sourceHeight) {
          return `${this.sourceWidth} / ${this.sourceHeight}`;
        }
      },
    },
  },
};
